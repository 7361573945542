<template>
  
    <div class="row">
   <div class="col-md-6">
      <div class="form-group">
         <label class="control-label text-left"> Topic Name <span class="data-v-tooltip" data-v-tooltip="The topic name will be displayed when viewing interactions and in topic menus." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label><input iservice-id="Name" type="text" class="form-control"><!--v-if-->
      </div>
   </div>
   <div class="col-md-6">
      <div class="form-group">
         <label class="control-label text-left">Parent Topic</label>
         <div>
            <div tabindex="-1" class="multiselect">
               <!-- Search --><input type="text" class="multiselect-search" modelvalue=""><!-- Tags (with search) --><!--v-if--><!-- Single label -->
               <div class="multiselect-single-label">All Topics</div>
               <!-- Multiple label --><!--v-if--><!-- Placeholder --><!--v-if--><!-- Spinner --><!--v-if--><!-- Clear --><!--v-if--><!-- Caret --><span class="multiselect-caret"></span><!-- Options -->
               <div class="multiselect-dropdown is-hidden" tabindex="-1">
                  <ul class="multiselect-options">
                     <li class="multiselect-group">
                        <div class="multiselect-group-label"><span>_iService</span></div>
                        <ul class="multiselect-group-options">
                           <li class="multiselect-option is-pointed" data-pointed="true"><span class="op-level-undefined">None - This is the root topic for the segment.</span></li>
                           <li class="multiselect-option is-selected"><span class="op-level-0">All Topics</span></li>
                           <li class="multiselect-option"><span class="op-level-1">_Feedback</span></li>
                           <li class="multiselect-option"><span class="op-level-1">_No Autoresponse</span></li>
                           <li class="multiselect-option"><span class="op-level-1">_Out of Office Notices</span></li>
                           <li class="multiselect-option"><span class="op-level-1">_Spam</span></li>
                           <li class="multiselect-option"><span class="op-level-2">GoDaddy</span></li>
                           <li class="multiselect-option"><span class="op-level-1">_Undeliverable</span></li>
                           <li class="multiselect-option"><span class="op-level-1">General Inquiry</span></li>
                           <li class="multiselect-option"><span class="op-level-1">Quarantine Reports</span></li>
                           <li class="multiselect-option"><span class="op-level-1">Sales</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Customers</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Demo</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Newsletter Subscription</span></li>
                           <li class="multiselect-option"><span class="op-level-1">Technical Support</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Abuse</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Answering Questions</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Batch Utility</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Bug Report</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Critical</span></li>
                           <li class="multiselect-option"><span class="op-level-2">FedEx ATD Notices</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Customer Enhancement Request</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Email Marketing</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Email Processing</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Forms</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Operations</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Reports</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Security</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Site configuration</span></li>
                           <li class="multiselect-option"><span class="op-level-2">Working With Contacts</span></li>
                           <li class="multiselect-option"><span class="op-level-1">Vendor Support</span></li>
                        </ul>
                     </li>
                  </ul>
                  <!--v-if--><!--v-if-->
               </div>
               <!-- Hacky input element to show HTML5 required warning --><!--v-if--><!-- Native input support --><!--v-if--><!-- Create height for empty input -->
               <div class="multiselect-spacer"></div>
            </div>
         </div>
      </div>
   </div>
   <div class="col-md-6">
      <div class="form-group">
         <label class="control-label text-left">Visibility <span class="data-v-tooltip" data-v-tooltip="To prevent customers from seeing this topic, set its visibility to Private." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
         <select class="form-control">
            <option value="public">Public</option>
            <option value="private">Private</option>
            <option value="deleted">Deleted</option>
         </select>
         <!--v-if-->
      </div>
   </div>
   <div class="col-md-6">
      <div class="form-group">
         <label class="control-label text-left">Customer Ticket Filter <span class="data-v-tooltip" data-v-tooltip="You can apply Filters to incoming questions submitted via customer ticket forms. Select the filter for the topic here. Filters for incoming customer email are set on the mailbox, not the topic." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
         <div tabindex="-1" class="multiselect" iservice-id="Filter">
            <!-- Search --><input type="text" class="multiselect-search"><!-- Tags (with search) --><!--v-if--><!-- Single label -->
            <div class="multiselect-single-label">-- None --</div>
            <!-- Multiple label --><!--v-if--><!-- Placeholder --><!--v-if--><!-- Spinner --><!--v-if--><!-- Clear --><!--v-if--><!-- Caret --><span class="multiselect-caret"></span><!-- Options -->
            <div class="multiselect-dropdown is-hidden" tabindex="-1">
               <ul class="multiselect-options">
                  <li class="multiselect-option is-selected"><span>-- None --</span></li>
                  <li class="multiselect-option"><span>_MasterGroup</span></li>
                  <li class="multiselect-option"><span>_MasterGroup-SupportNoUndeliv</span></li>
                  <li class="multiselect-option"><span>_Topic Changes</span></li>
                  <li class="multiselect-option"><span>_TopicChanges-NoUndeliv</span></li>
                  <li class="multiselect-option"><span>Allow External Agent</span></li>
                  <li class="multiselect-option"><span>Demo Request</span></li>
                  <li class="multiselect-option"><span>ESS Outside Region Auto Resolve</span></li>
                  <li class="multiselect-option"><span>Event Sentry</span></li>
                  <li class="multiselect-option"><span>Facebook-Birthday</span></li>
                  <li class="multiselect-option"><span>Facebook-Friends Request</span></li>
                  <li class="multiselect-option"><span>Facebook-FROM</span></li>
                  <li class="multiselect-option"><span>Facebook-Weekly Updates</span></li>
                  <li class="multiselect-option"><span>GoDaddy</span></li>
                  <li class="multiselect-option"><span>QuarantineReport</span></li>
                  <li class="multiselect-option"><span>SendAutoresponse-Test</span></li>
                  <li class="multiselect-option"><span>SPAM-Body</span></li>
                  <li class="multiselect-option"><span>SPAM-Contact</span></li>
                  <li class="multiselect-option"><span>SPAM-From</span></li>
                  <li class="multiselect-option"><span>SPAM-Russian or Chinese</span></li>
                  <li class="multiselect-option"><span>Spam-Subject</span></li>
                  <li class="multiselect-option"><span>SPAM-Visual Visitor Report</span></li>
                  <li class="multiselect-option"><span>TimeStampTest</span></li>
                  <li class="multiselect-option"><span>Topic - Out of Office</span></li>
                  <li class="multiselect-option"><span>Topic-fail2ban</span></li>
                  <li class="multiselect-option"><span>Topic-FedEx ATD1</span></li>
                  <li class="multiselect-option"><span>Topic-FedEx ATD2</span></li>
                  <li class="multiselect-option"><span>Topic-No Auto Response</span></li>
                  <li class="multiselect-option"><span>Topic-Sales</span></li>
                  <li class="multiselect-option"><span>Twitter-from</span></li>
                  <li class="multiselect-option"><span>Undeliverable-sender</span></li>
                  <li class="multiselect-option"><span>Undeliverable-Set Bounce Reason</span></li>
                  <li class="multiselect-option"><span>Undeliverable-subject</span></li>
                  <li class="multiselect-option"><span>Z-test-multipleto</span></li>
                  <li class="multiselect-option"><span>Z-test-NWD-email-crane</span></li>
               </ul>
               <!--v-if--><!--v-if-->
            </div>
            <!-- Hacky input element to show HTML5 required warning --><!--v-if--><!-- Native input support --><!--v-if--><!-- Create height for empty input -->
            <div class="multiselect-spacer"></div>
         </div>
      </div>
   </div>
   <div class="col-md-6">
      <div class="form-group">
         <label class="control-label text-left">Message Acknowledgment Notification <span class="data-v-tooltip" data-v-tooltip="Select the notification template to send to the customer when we receive their question in this topic." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
         <div tabindex="-1" class="multiselect" iservice-id="NotifyAck">
            <!-- Search --><input type="text" class="multiselect-search"><!-- Tags (with search) --><!--v-if--><!-- Single label -->
            <div class="multiselect-single-label">-- None --</div>
            <!-- Multiple label --><!--v-if--><!-- Placeholder --><!--v-if--><!-- Spinner --><!--v-if--><!-- Clear --><!--v-if--><!-- Caret --><span class="multiselect-caret"></span><!-- Options -->
            <div class="multiselect-dropdown is-hidden" tabindex="-1">
               <ul class="multiselect-options">
                  <li class="multiselect-option is-selected"><span>-- None --</span></li>
                  <li class="multiselect-option"><span>-- Segment Default --</span></li>
                  <li class="multiselect-option"><span>Abuse Auto Response New</span></li>
                  <li class="multiselect-option"><span>Cust Change Req</span></li>
                  <li class="multiselect-option"><span>Cust Change Req - New</span></li>
                  <li class="multiselect-option"><span>Demo Request New</span></li>
                  <li class="multiselect-option"><span>Maintenance Notice New</span></li>
                  <li class="multiselect-option"><span>Sales Response</span></li>
                  <li class="multiselect-option"><span>Services Are Back Online</span></li>
                  <li class="multiselect-option"><span>Standard Auto Response New</span></li>
                  <li class="multiselect-option"><span>Support - Critical Acknowledgement New</span></li>
                  <li class="multiselect-option"><span>Support - Std Acknowledgment New</span></li>
                  <li class="multiselect-option"><span>Time Test</span></li>
                  <li class="multiselect-option"><span>Unscheduled maintenance  problems</span></li>
               </ul>
               <!--v-if--><!--v-if-->
            </div>
            <!-- Hacky input element to show HTML5 required warning --><!--v-if--><!-- Native input support --><!--v-if--><!-- Create height for empty input -->
            <div class="multiselect-spacer"></div>
         </div>
      </div>
   </div>
   <div class="col-md-6">
      <div class="form-group">
         <label class="control-label text-left">Agent Notification <span class="data-v-tooltip" data-v-tooltip="Select the notification template to send to agents when new questions are created in this topic." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
         <div tabindex="-1" class="multiselect" iservice-id="NotifyAgent">
            <!-- Search --><input type="text" class="multiselect-search"><!-- Tags (with search) --><!--v-if--><!-- Single label -->
            <div class="multiselect-single-label">-- None --</div>
            <!-- Multiple label --><!--v-if--><!-- Placeholder --><!--v-if--><!-- Spinner --><!--v-if--><!-- Clear --><!--v-if--><!-- Caret --><span class="multiselect-caret"></span><!-- Options -->
            <div class="multiselect-dropdown is-hidden" tabindex="-1">
               <ul class="multiselect-options">
                  <li class="multiselect-option is-selected"><span>-- None --</span></li>
                  <li class="multiselect-option"><span>-- Segment Default --</span></li>
                  <li class="multiselect-option"><span>Agent Nofify-FedEx New</span></li>
                  <li class="multiselect-option"><span>Agent Notify - Email Reply New</span></li>
                  <li class="multiselect-option"><span>Agent Notify - Feature Request Notice New</span></li>
                  <li class="multiselect-option"><span>Agent Notify - iService</span></li>
                  <li class="multiselect-option"><span>Agent Notify - iService New</span></li>
                  <li class="multiselect-option"><span>Agent Notify - Support-Critical</span></li>
                  <li class="multiselect-option"><span>Agent Notify at Start</span></li>
                  <li class="multiselect-option"><span>External Agent</span></li>
                  <li class="multiselect-option"><span>External Agent New</span></li>
                  <li class="multiselect-option"><span>Time Test</span></li>
                  <li class="multiselect-option"><span>Unscheduled maintenance  problems</span></li>
               </ul>
               <!--v-if--><!--v-if-->
            </div>
            <!-- Hacky input element to show HTML5 required warning --><!--v-if--><!-- Native input support --><!--v-if--><!-- Create height for empty input -->
            <div class="multiselect-spacer"></div>
         </div>
      </div>
   </div>
   <div class="col-md-6">
      <div class="form-group">
         <label class="control-label text-left">Service Level <span class="data-v-tooltip" data-v-tooltip="Select the service level that will apply to questions in this topic. When multiple service levels are available for a question, iService will use the one that has the lowest value." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
         <div tabindex="-1" class="multiselect" iservice-id="ServiceLevel">
            <!-- Search --><input type="text" class="multiselect-search"><!-- Tags (with search) --><!--v-if--><!-- Single label -->
            <div class="multiselect-single-label">-- Segment Default --</div>
            <!-- Multiple label --><!--v-if--><!-- Placeholder --><!--v-if--><!-- Spinner --><!--v-if--><!-- Clear --><!--v-if--><!-- Caret --><span class="multiselect-caret"></span><!-- Options -->
            <div class="multiselect-dropdown is-hidden" tabindex="-1">
               <ul class="multiselect-options">
                  <li class="multiselect-option is-selected"><span>-- Segment Default --</span></li>
                  <li class="multiselect-option"><span>Customer Service Level</span></li>
                  <li class="multiselect-option"><span>Low Priority</span></li>
                  <li class="multiselect-option"><span>Tenant Default</span></li>
                  <li class="multiselect-option"><span>test of crash</span></li>
               </ul>
               <!--v-if--><!--v-if-->
            </div>
            <!-- Hacky input element to show HTML5 required warning --><!--v-if--><!-- Native input support --><!--v-if--><!-- Create height for empty input -->
            <div class="multiselect-spacer"></div>
         </div>
      </div>
   </div>
   <div class="clearfix"></div>
   <div class="col-md-6">
      <div class="box box-primary">
         <div class="box-header with-border">
            <h3 class="box-title text-right"> Skills Required For Get Next <span class="data-v-tooltip" data-v-tooltip="Select the skills that an agent must have to be assigned questions from this topic when they click the Get Next button." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></h3>
         </div>
         <div class="box-body">
            <div class="form-group">
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="17"> Build Tracking</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="5"> critical</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="12"> Doug</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="13"> EventSentry</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="16"> FedEx</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="14"> GitHub</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="7"> GoDaddy</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="15"> IT Operations</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="9"> JOE</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="1"> no_route</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="6"> not_phone</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="3"> quarantine reports</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="10"> Sales</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="11"> Scott</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="18"> steve</label></div>
               <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="2"> support</label></div>
            </div>
         </div>
      </div>
   </div>
   <div class="col-md-6">
      <div class="box box-primary">
         <div class="box-header with-border">
            <h3 class="box-title text-right">Interaction Properties <span class="data-v-tooltip" data-v-tooltip="Select the interaction properties that are associated with this topic. These will be presented to agents when adding tickets to the topic and composing answers. Properties that are customer editable will be presented to customers when they submit questions using builtin contact forms. Properties with a red label are configured as Required." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></h3>
         </div>
         <div class="box-body">
            <label class="col-md-12 control-label text-left">_iService Properties</label>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="66"> Archived (Answer) </label></div>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="15"> Bounce Reason (Case) </label></div>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="67"> Deleted (Answer) </label></div>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="65"> DoNotReply (Question) </label></div>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="9"> Due Date (Case) </label></div>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="10"> Est Hours (Answer) </label></div>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="64"> HasBeenRead (Answer) </label></div>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="8"> iService Version (Case) </label></div>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="2"> Issue Priority (Case) </label></div>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="21"> Issue Resolution (Case) </label></div>
            <div class="checkbox" iservice-id-prefix="'PropsThis'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="20"> Support Type (Case) </label></div>
            <h4 class="col-md-12 mtop-15">Shared Properties From Other Segments</h4>
            <div>
               <label class="col-md-12 control-label text-left">Compliance &amp; Security</label>
               <div class="checkbox" iservice-id-prefix="'PropOther'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="53"> Approved (Question) </label></div>
               <div class="checkbox" iservice-id-prefix="'PropOther'"><label class=""><input iservice-id="Selected" type="checkbox" class="form-check-input" value="46"> Compliance Task (Question) </label></div>
            </div>
         </div>
      </div>
   </div>
   <div class="col-md-12">
      <div class="row">
         <div class="col-12"><button iservice-id="Save" class="btn btn-primary mright-5">Save</button><button class="btn btn-primary"><i class="fa fa-eye"></i> Preview</button></div>
         <div class="col-12">
            <!--v-if-->
         </div>
      </div>
   </div>
</div>

  
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
    import Toggle from '@vueform/toggle'
    import Multiselect from '@vueform/multiselect'
    import iServiceSwitch from '../components/iServiceSwitch.vue';
    import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';

    export default {
      name: 'StockResponse Detail',
      props: ['formTitle'],
       components: {
         Multiselect,
         iServiceSwitch,
         Toggle,
         VariableAndStockResponses
      },
      data: function () {
        return {
           allowmultiple : false,
           customerviewable : false
        }
      },
      events: {
       
      },
      methods: {
      
      }

    }
</script>