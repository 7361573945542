<template>
           <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Property Name</label
                    ><input iservice-id="Name" type="text" class="form-control"><!--v-if-->
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Property Type</label>
                    <div>
                     <Multiselect
                            v-model="propertytype"
                            placeholder="-None-"
                            label="name"
                            :groups="false"
                            :searchable="true"
                            :options="[
                                    { value: 'Answer', name: 'Answer',level:'0'},
                                     { value: 'Case', name: 'Case',level:'0'},
                                      { value: 'Question', name: 'Question',level:'0'},
                                
                            ]"
                            >
                            <template  v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                     </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Value Type</label>
                    <div>
                       <Multiselect
                                v-model="value"
                                placeholder="Select or search for value type"
                                label="name"
                                :groups="true"
                                :searchable="true"
                                :options="[
                                    {
                                    label: 'Builtin Lists',
                                    options:  [
                                        { value: 'Date', name: 'Date',level:'1'},
                                        { value: 'Date Time', name: 'Date Time',level:'1'},
                                        { value: 'Integer', name: 'Integer',level:'1'},
                                        { value: 'Number', name: 'Number',level:'1'}
                                    ]
                                    },
                                    {
                                    label: 'Lists',
                                        options:  [
                                            { value: 'Buyer Type', name: 'Buyer Type',level:'1'},
                                            { value: 'Buying Window', name: 'Buying Window',level:'1'},
                                            { value: 'Customer Type', name: 'Customer Type',level:'1'}
                                        ]
                                    }
                                    
                                ]"
                                >
                                <template v-slot:option="{ option }">
                                   <span v-if="Object.keys(option).length >= 0">
                                      <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                   </span>
                                    <span v-if="Object.keys(option).length <= 0">
                                       There are no alert notification templates in the segment.
                                    </span>
                                </template>
                        </Multiselect>
                    </div>
                </div>
                
            </div>
            <h4 class="col-md-12">Optional Settings</h4>
            <div class="col-md-6">
                <div class="checkbox">
                    <label class="control-label text-left"> 
                         <Toggle/> Required Property
                    </label>
                </div>
               <div class="checkbox">
                    <label class="control-label text-left"> 
                        <Toggle/> Shared Property
                    </label>
                    
                </div>
             </div>
              <div class="col-md-6">
                <div class="checkbox">
                     <label class="control-label text-left"> 
                        <Toggle/> Allowed Multiple Value
                    </label>
                   
                </div>
                <div class="checkbox">
                        <label class="control-label text-left"> 
                                <Toggle/> Customer Veiwable
                        </label>
                    </div>
             </div>
           </div>
           <div class="row">
            <div class="col-12">
                <button iservice-id="Save" class="btn btn-primary">Save</button>
            </div> 
                <div class="col-md-12"><hr></div>
            <div class="col-md-12">
                <h4> This property is used in the following locations </h4>
                <div class="row">
                    <div class="col-md-4">
                        <div class="nested-panel-heading skill-heading">
                            <h3 class="nested-title">Skills</h3>
                        </div>
                        <div class="skill-list"><span class="skill-item">None</span></div>
                    </div>
                    <div class="col-md-4">
                        <div class="nested-panel-heading skill-heading">
                            <h3 class="nested-title">Topics</h3>
                        </div>
                        <div class="skill-list"><span class="skill-item">None</span></div>
                    </div>
                    <div class="col-md-4">
                        <div class="nested-panel-heading skill-heading">
                            <h3 class="nested-title">Filters</h3>
                        </div>
                        <div class="skill-list"><span class="skill-item">None</span></div>
                    </div>
                    <div class="col-md-4">
                        <div class="nested-panel-heading skill-heading">
                            <h3 class="nested-title">Alerts</h3>
                        </div>
                        <div class="skill-list"><span class="skill-item">None</span></div>
                    </div>
                    <div class="col-md-4">
                        <div class="nested-panel-heading skill-heading">
                            <h3 class="nested-title">Service Levels</h3>
                        </div>
                        <div class="skill-list"><span class="skill-item">None</span></div>
                    </div>
                </div>
                </div>

        
            </div>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
    import Toggle from '@vueform/toggle'
    import Multiselect from '@vueform/multiselect'
    import iServiceSwitch from '../components/iServiceSwitch.vue';

    export default {
      name: 'Interaction Property Form',
      props: ['formTitle'],
       components: {
         Multiselect,
         iServiceSwitch,
         Toggle
      },
      data: function () {
        return {
         
        }
      },
      events: {
       
      },
      methods: {
      
      }

    }
</script>