<template>
   <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left"> Name</label><input iservice-id="Name" type="text" class="form-control"><!--v-if-->
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Expression</label><input iservice-id="Description" type="text" class="form-control"><!--v-if-->
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Description</label><input iservice-id="Description" type="text" class="form-control"><!--v-if-->
                </div>
            </div>
            <div class="col-md-12">
                    <label class="control-label text-left">Match In</label>
                    <div class="checkbox"><label><input type="checkbox" class="form-check-input" value="true"> Subject</label></div>
                    <div class="checkbox"><label><input type="checkbox" class="form-check-input" value="true"> Subject</label></div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                 <label class="control-label text-left">Filter Action</label>
                 <section class="container-fluid search-parameters">
                    <fieldset class="action">
                        <div class="panel panel-default col-12 bg-gray-light action-panel">
                            <div class="col-md-12">
                                <div class="row formstep">
                                <div class="col-md-9 box-bg-white ptop-15 pbottom-15 white-container">
                                    <div class="row">
                                        <div class="row formstep box-bg-white action-step-forwardexternal">
                                            <div class="col-md-12">
                                            <h4>Forward To External</h4>
                                            </div>
                                            <div class="col-md-12"> Interaction will be answerable with f/forward-external by external agents. </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 btn-container"><button type="button" class="btn btn-link btn-lg btn-sort"><i class="fa fa-chevron-circle-up text-primary"></i></button><button type="button" class="btn btn-link btn-lg btn-sort"><i class="fa fa-chevron-circle-down text-primary"></i></button><button type="button" class="btn btn-link btn-lg btn-sort"><i class="fa fa-times-circle text-red"></i></button></div>
                                </div>
                                <div class="row"><button class="btn btn-lg btn-link pull-left" type="button"><i class="fa fa-plus-circle text-light-blue"></i> Add Steps </button></div>
                            </div>
                        </div>
                    </fieldset>
                    </section>
              </div>
            </div>
          </div>
           <div class="row">
                <div class="col-12">
                    <button iservice-id="Save" class="btn btn-primary">Save</button>
                    <button iservice-id="Cancel" class="btn btn-link">Cancel</button>
                </div> 
            </div>
           
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
    import Toggle from '@vueform/toggle'
    import Multiselect from '@vueform/multiselect'
    import iServiceSwitch from '../components/iServiceSwitch.vue';
    import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';

    export default {
      name: 'StockResponse Detail',
      props: ['formTitle'],
       components: {
         Multiselect,
         iServiceSwitch,
         Toggle,
         VariableAndStockResponses
      },
      data: function () {
        return {
           allowmultiple : false,
           customerviewable : false
        }
      },
      events: {
       
      },
      methods: {
      
      }

    }
</script>