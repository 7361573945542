<template>
   <div class="row">
     <div class="box box-info">
        <div class="box-body">
           <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label class="control-label text-left"> Name</label><input iservice-id="Name" type="text" class="form-control"><!--v-if-->
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label class="control-label text-left">Description</label><input iservice-id="Description" type="text" class="form-control"><!--v-if-->
                </div>
            </div>
            <div class="col-md-4">
                <label class="control-label text-left mt-4"> 
                    <Toggle/> Shared Property
                </label>  
             </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label class="control-label text-left">Notification Type</label>
                    <div class="">
                     <Multiselect
                            v-model="propertytype"
                            placeholder="Select Notification"
                            label="name"
                            :groups="false"
                            :searchable="true"
                            :options="[
                                    { value: 'Agent', name: 'Agent',level:'0'},
                                    { value: 'Alert', name: 'Alert',level:'0'},
                                
                            ]"
                            >
                            <template  v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                     </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label class="control-label text-left">Notification Template</label>
                    <div class="">
                     <Multiselect
                            v-model="propertytype"
                            placeholder="Select Notification Template"
                            label="name"
                            :groups="false"
                            :searchable="true"
                            :options="[
                                    { value: 'Template 1', name: 'Template 1',level:'0'},
                            ]"
                            >
                            <template  v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                     </div>
                </div>
            </div>

             <div class="col-md-12 mt-3">
                <div class="form-group">
                    <label class="control-label text-left">Response Body</label> 
                    <div class="btn-group position-absolute pull-right translate-middle-y mright-15 pt-15 end-0">
                         <VariableAndStockResponses></VariableAndStockResponses>
                     </div>
                     <div class="clerfix"></div>
                    <div class="">
                         <div class=" text-editor-container">
                                <textarea class="form-control" rows="10" placeholder="" id="editorgetthis" ckeditor ><p>Hello,</p>

                                    <p>I&#39;m looking for s small TV for our bedroom, but don&#39;t want to spend more than $100. Do you have any option for me to consider?</p>
                                    
                                    <p>Regards,</p>
                                    
                                    <p>John Doe</p>
                                </textarea>
                            </div>
                     </div>
                </div>
            </div>
          </div>
           <div class="row">
                <div class="col-12">
                    <button iservice-id="Create" class="btn btn-primary">Create</button>
                    <button iservice-id="Cancel" class="btn btn-link">Cancel</button>
                </div> 
            </div>
           
        </div>
      </div> 
   </div>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
    import Toggle from '@vueform/toggle'
    import Multiselect from '@vueform/multiselect'
    import iServiceSwitch from '../components/iServiceSwitch.vue';
    import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';

    export default {
      name: 'New Notification',
      props: ['formTitle'],
       components: {
         Multiselect,
         iServiceSwitch,
         Toggle,
         VariableAndStockResponses
      },
      data: function () {
        return {
           allowmultiple : false,
           customerviewable : false
        }
      },
      events: {
       
      },
      methods: {
      
      }

    }
</script>