<template>

<div class="box-content box-bg-color">
   <div class="row">
      <div class="col-md-6">
         <div class="box box-primary">
            <div class="box-header with-border">
               <h3 class="box-title text-right">Incoming Mail Configuration</h3>
            </div>
            <div class="box-body">
               <div class="row">
                  <div class="col-md-12">
                     <div class="form-group">
                        <label class="control-label text-left"> Protocol <span class="data-v-tooltip" data-v-tooltip="Choose the appropriate protocol for retrieving incoming email." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                        <div class="col-md-12">
                           <select class="form-control">
                              <option value="imap">IMAP</option>
                              <option value="pop">POP</option>
                              <option value="ews">EWS</option>
                              <option value="ews-oauth">EWS OAuth</option>
                           </select>
                        </div>
                     </div>
                     <div class="form-group" >
                        <label class="col-md-12 control-label text-left">Mail Server Name</label><label class="col-md-12 control-label text-left" style="display: none;">Mail Server Name</label><label class="col-md-12 control-label text-left" style="display: none;">Path To EWS</label>
                        <div class="col-md-12"><input type="text" class="form-control"><span>Ex. pop.example.com </span><span style="display: none;">Ex. imap.example.com</span><span style="display: none;">Ex. https://outlook.office365.com/EWS/exchange.asmx </span></div>
                     </div>
                     <div class="form-group" style="display: none;">
                        <label class="col-md-12 control-label text-left">EWS Application ID</label>
                        <div class="col-md-12"><input type="text" class="form-control has-error"></div>
                     </div>
                     <div class="form-group" style="display: none;">
                        <label class="col-md-12 control-label text-left">EWS Client Secret</label>
                        <div class="col-md-12"><input type="text" class="form-control has-error"></div>
                     </div>
                     <div class="form-group" style="display: none;">
                        <label class="col-md-12 control-label text-left">EWS Tenant ID</label>
                        <div class="col-md-12"><input type="text" class="form-control has-error"></div>
                     </div>
                     <div class="form-group">
                        <div class="col-md-12"><input type="checkbox" class="form-check-input"> Use Encryption <span class="data-v-tooltip" data-v-tooltip="If your email server requires encryption, enable this option and set your port number to the appropriate value (typically 995 for POP or 993 for IMAP)." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></div>
                     </div>
                     <div class="form-group">
                        <label class="col-md-12 control-label text-left">Port Number <span class="data-v-tooltip" data-v-tooltip="Enter the port number for accessing the mailbox. Common port numbers are 110 for POP and 143 for IMAP." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                        <div class="col-md-12"><input type="text" class="form-control"></div>
                     </div>
                     <div class="form-group">
                        <label class="col-md-12 control-label text-left"> Username <span class="data-v-tooltip" data-v-tooltip="Enter the username required to log into the mailbox." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                        <div class="col-md-12"><input type="text" class="form-control"></div>
                     </div>
                     <div class="form-group" v-if="setPassword">
                        <label class="col-md-12 control-label text-left">Password <span class="data-v-tooltip" data-v-tooltip="Enter the password required to log into the mailbox." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                         <div class="col-md-12">
                            <input type="text" class="form-control has-error" value="">
                         </div> 
                     </div>
                     <div class="form-group" v-if="!setPassword">
                        <a href="javascript:void(0)" class="col-md-6" @click="setPassword = true;">Set New Password</a>
                     </div>
                     <div class="form-group">
                        <label class="col-md-8 control-label text-left">Default Topic For Incoming Messages <span class="data-v-tooltip" data-v-tooltip="This is the default topic assigned to email retrieved from this mailbox. You can use a filter to change the topic and take other actions on the messages." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label><label class="col-md-4 control-label text-end"><a target="_blank" href="/app/admin/segment/5/topic/47" class="help-link text-primary text-right">View</a></label>
                        <div class="col-md-12">
                           <Multiselect
                            v-model="topicselection"
                            placeholder="-Select Topic-"
                            label="name"
                            :groups="false"
                            :searchable="true"
                            :options="[
                                    { value: 'Abuse', name: 'Abuse',level:'0'},
                                
                            ]"
                            >
                            <template  v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                        </div>
                     </div>
                     <h4>Optional Settings</h4>
                     <div class="form-group">
                        <label class="col-md-8 control-label text-left"> Filter <span class="data-v-tooltip" data-v-tooltip="Choose a filter to apply to messages received in this mailbox." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label><label class="col-md-4 control-label text-right" style="display: none;"><a target="_blank" href="/app/admin/segment/5/filter/" class="help-link text-primary text-right">View</a></label>
                        <div class="col-md-12">
                           <div tabindex="-1" class="multiselect" iservice-id="Filter">
                              <!-- Search --><input type="text" class="multiselect-search"><!-- Tags (with search) --><!--v-if--><!-- Single label --><!--v-if--><!-- Multiple label --><!--v-if--><!-- Placeholder -->
                              <div class="multiselect-placeholder">Select a filter</div>
                              <!-- Spinner --><!--v-if--><!-- Clear --><!--v-if--><!-- Caret --><span class="multiselect-caret"></span><!-- Options -->
                              <div class="multiselect-dropdown is-hidden" tabindex="-1">
                                 <ul class="multiselect-options">
                                    <li class="multiselect-option"><span></span></li>
                                    <li class="multiselect-option"><span>(_iService) _MasterGroup</span></li>
                                   
                                 </ul>
                                 <!--v-if--><!--v-if-->
                              </div>
                              <!-- Hacky input element to show HTML5 required warning --><!--v-if--><!-- Native input support --><!--v-if--><!-- Create height for empty input -->
                              <div class="multiselect-spacer"></div>
                           </div>
                        </div>
                     </div>
                     <div class="form-group">
                        <div class="col-md-12">
                           <div class="iservice-switch"><label class="switch"><input type="checkbox" iservice-switch=""><i></i>  Enable Mail Retrieving </label>  <span class="data-v-tooltip" data-v-tooltip="Enable this setting to allow access to the mailbox. When disabled, this mailbox will be skipped by the mail popper." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></div>
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-md-6">
         <div class="box box-primary">
            <div class="box-header with-border">
               <h3 class="box-title text-right">Outgoing Mail Configuration</h3>
            </div>
            <div class="box-body">
               <div class="row">
                  <div class="col-md-12">
                     <div class="form-group">
                        <label class="col-md-12 control-label text-left">Protocol <span class="data-v-tooltip" data-v-tooltip="Choose the appropriate protocol for sending outgoing email." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                        <div class="col-md-12">
                           <select class="form-control" v-model="protocall">
                              <option value="smtp">SMTP</option>
                              <option value="smtpauth">SMTP Auth</option>
                              <option value="ews">EWS</option>
                              <option value="ews-oauth">EWS OAuth</option>
                           </select>
                        </div>
                     </div>
                     <div class="form-group" >
                        <label class="col-md-12 control-label text-left" v-if="protocall == 'smtp'">SMTP Server Name</label>
                        <label class="col-md-12 control-label text-left" v-if="protocall == 'ews'">Path To EWS</label>
                        <div class="col-md-12" v-if="protocall == 'ews'"><input type="text" class="form-control"><span >Ex. https://outlook.office365.com/EWS/exchange.asmx </span></div>
                     </div>
                     <div class="form-group" v-if="protocall == 'ews-oauth'">
                        <label class="col-md-12 control-label text-left">EWS Application ID</label>
                        <div class="col-md-12"><input type="text" class="form-control has-error"></div>
                     </div>
                     <div class="form-group" v-if="protocall == 'ews-oauth'">
                        <label class="col-md-12 control-label text-left">EWS Client Secret</label>
                        <div class="col-md-12"><input type="text" class="form-control has-error"></div>
                     </div>
                     <div class="form-group" v-if="protocall == 'ews-oauth'">
                        <label class="col-md-12 control-label text-left">EWS Tenant ID</label>
                        <div class="col-md-12"><input type="text" class="form-control has-error"></div>
                     </div>
                     <div class="form-group">
                        <div class="col-md-12"><input type="checkbox" class="form-check-input"> Use Encryption <span class="data-v-tooltip" data-v-tooltip="Select this option to enable encryption if required by your server." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></div>
                     </div>
                     <div class="form-group" v-if="protocall == 'smtpauth'">
                        <label class="col-md-12 control-label text-left group-input-label">Port Number <span class="data-v-tooltip" data-v-tooltip="Enter the port number for sending mail. The common port number is 465 for SMTP Submission." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                        <div class="col-md-12"><input type="text" class="form-control"></div>
                     </div>
                     <div class="form-group" v-if="protocall == 'smtpauth' || protocall == 'ews' || protocall == 'ews-oauth'">
                        <label class="col-md-12 control-label text-left">Username <span class="data-v-tooltip" data-v-tooltip="Enter the username required to log into the mailbox." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                        <div class="col-md-12"><input type="text" class="form-control has-error"></div>
                     </div>
                     
                     <div class="form-group" v-if="setPasswordAuth && protocall == 'smtpauth' || protocall == 'ews' || protocall == 'ews-oauth'">
                        <label class="col-md-12 control-label text-left">Password <span class="data-v-tooltip" data-v-tooltip="Enter the password required to log into the mailbox." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                         <div class="col-md-12">
                            <input type="text" class="form-control has-error" value="">
                         </div> 
                     </div>
                     <div class="form-group" v-if="!setPasswordAuth">
                        <a href="javascript:void(0)" class="col-md-6" @click="setPasswordAuth = true;">Set New Password</a>
                     </div>
                     <div class="form-group"><label class="col-md-12 control-label text-left">Display Name <span class="data-v-tooltip" data-v-tooltip="This is the display name used for messages sent from this mailbox (e.g., Support Team)." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label><div class="col-md-12"><input type="text" class="form-control"></div></div>
                     <div class="form-group">
                        <label class="col-md-12 control-label text-left">Reply To <span class="data-v-tooltip" data-v-tooltip="This is the email address used for the From field when sending from this mailbox. When recipients reply, this is the Reply To address they will see (e.g., support@example.com)." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                        <div class="col-md-12"><input type="text" class="form-control"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="row">
   <div class="col-12">
      <button iservice-id="Create" class="btn btn-primary">Create</button>
      <button iservice-id="Cancel" class="btn btn-link">Cancel</button>
   </div>
</div>

       
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
    import Toggle from '@vueform/toggle'
    import Multiselect from '@vueform/multiselect'
    import iServiceSwitch from '../components/iServiceSwitch.vue';

    export default {
      name: 'Interaction Property Form',
      props: ['formTitle'],
       components: {
         Multiselect,
         iServiceSwitch,
         Toggle,
      },
      data: function () {
        return {
           allowmultiple : false,
           customerviewable : false,
           setPassword : false,
           setPasswordAuth : false,
           protocall : ''
        }
      },
      events: {
       
      },
      methods: {
      
      }

    }
</script>