<template>
    <div class="row">
        <div class="col-md-12">
        <!-- Custom Tabs -->
            <div class="row">
                <div class="col-md-12">
                    <div class="box box-info">
                        <div class="box-body table-responsive">
                        <div class="notdatatable form-inline mbottom-15">
                            <div class="table-controls table-columns">
                                <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                            </div>
                            <div class="table-controls table-pagesize table-columns">
                                <label>
                                    Show 
                                    <select class="form-control input-xs">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    </select>
                                    entries 
                                </label>
                            </div>
                            
                            <div class="table-controls table-filter">Filter Alerts <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                        </div>
                        <table class="table table-striped table-hover">
                            <thead>
                            <tr id="row1">
                                <!--<th class="no-sort"  name="name" width="30"></th>-->
                                <th class="text-capitalize sorting">
                                    Display Name
                                </th>
                                <th class="text-capitalize sorting">
                                     Reply To Address
                                </th>
                                <th class="text-capitalize sorting">
                                    Default Topic
                                </th>
                                <th class="text-capitalize sorting">
                                   Enable
                                </th>
                                <th class="text-capitalize sorting" >
                                 Incoming Server Name
                                </th>
                                <th class="text-capitalize sorting">
                                 Errors
                                </th>
                                <th class="text-capitalize sorting" style="display:none">
                                 Incoming Protocol
                                </th>
                                 <th class="text-capitalize sorting" style="display:none">
                                 Outgoing Protocol
                                </th>
                                 <th class="text-capitalize sorting" style="display:none">
                                 Incoming Username
                                </th>
                                 <th class="text-capitalize sorting" style="display:none">
                                 Incoming Password
                                 </th>
                                  <th class="text-capitalize sorting" style="display:none">
                                 Incoming Port Number
                                 </th>
                                  <th class="text-capitalize sorting" style="display:none">
                                 Filter
                                 </th>
                                  <th class="text-capitalize sorting" style="display:none">
                                 Incoming User Encryption
                                 </th>
                                  <th class="text-capitalize sorting" style="display:none">
                                 Outgoing  User Encryption
                                 </th>
                                   <th class="text-capitalize sorting" style="display:none">
                                 Outgoing  Server Name
                                 </th>
                                   <th class="text-capitalize sorting" style="display:none">
                                 Outgoing  Port Number
                                 </th>
                                   <th class="text-capitalize sorting" style="display:none">
                                 Outgoing  Username
                                 </th>
                                 <th class="text-capitalize sorting" style="display:none">
                                 Outgoing  Password
                                 </th>
                             
                            </tr>
                            </thead>
                            <tbody>
                            <tr id="row2" @click="showMessageDetails('','row2')" v-bind:class = "showDetails.active_el == 'row2' ?'row-active':''">
                               
                                <td>
                                	Abuse Support
                                </td>
                                <td>
                                   abuse@example.com
                                </td>
                                <td>
                                 Abuse
                                </td>
                                <td>
                                <Toggle/>
                               </td>
                                <td>
                                mail.​1to1servic​e.​com
                                </td>
                                <td>
                                1
                                </td>
                               
                               
                              
                            </tr>

                            
                            </tbody>
                        </table>
                        <div id="Main_MyDashboard_Recent_TableInfo">
                            <div class="notdatatable table-info">Showing 1 to 2 of 2 entries</div>
                            <div class="dataTables_paginate notdatatable table-pagination text-end">
                                <ul class="pagination">
                                    <li><a href="">Previous</a></li>
                                    <li class="active"><a href="">1</a></li>
                                    <li><a href="">Next</a></li>
                                </ul>
                            </div>
                            </div>
                        <!-- Data loader start
                        <div class="data-loader">
                            <i class="fa fa-spinner fa-spin fa-fw"></i>
                        </div>  -->
                        <!-- Data loader end-->
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                </div>
            </div>
        </div>
        <!-- /.col -->
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <button  class="btn btn-primary" v-on:click="showNewAlert()">New Mailbox Property</button>
           <button v-if="showDetails.showMailboxDetails"  class="btn btn-danger" v-on:click="showDeleteAlert()">Delete Mailbox Property</button>
        </div>
      </div>
    </div>

    <div class="expandedrowcontent box-bg-color" v-if="showDetails.showNew">
        <div class="box box-info with-border">
            <div class="box-body table-responsive">
                <div class="box-header with-border">
                    <i class="fa fa-globe"></i>
                    <h3 class="box-title text-right">New Mailbox Property</h3>
                </div>
                <div class="box-body">
                  <NewMailboxPropertyForm form-title="New Alerts"></NewMailboxPropertyForm>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="alert alert-danger alert-dismissible alert-inline pull-left mbottom-15">
                <button type="button" class="close">×</button>
                <h4><i class="icon fa fa-warning"></i>There are no replacement mailboxes available</h4>
                <div class="mtop-15" style="">This is the only mailbox in your segment, so you can't delete this
                    one. You must create a new mailbox that you can select as a
                    replacement before you can continue. </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="showDetails.deleteAlert">
        <div class="col-md-12" iservice-id-prefix="'Delete'">
             <div class="box box-danger" iservice-id="'Delete'">
                <div class="box-header with-border">
                    <i class="fa fa-warning"></i>
                    <h3 class="box-title text-right">Alert</h3>
                </div>
                <div class="box-body">
                    <section class="box-content box-bg-color">
                    <div class="row">
                        <div class="form-horizontal" v-if="showDetails.deleteMailboxAlert">
                            <div class="col-md-12">
                                <p>This mailbox is set as the default for the segment. Select a new mailbox for the segment default and then click Continue.</p>   
                            </div>
                            <div class="col-md-12">
                              <div class="w-50 float-start">
                                <Multiselect
                                    v-model="propertytype"
                                    placeholder="-Select or search for a mailbox to receive interaction-"
                                    label="name"
                                    :groups="false"
                                    :searchable="true"
                                    :class="w-50"
                                    :options="[
                                            { value: 'Sales(Default)', name: 'sales@1to1service.com',level:'0'},
                                        
                                    ]"
                                    >
                                    <template  v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.value }} <br>{{option.name}}</span>
                                    </template>
                                </Multiselect>
                                
                                </div>
                                <div class="w-50 float-start ps-2"><button iservice-id="Continue" class="btn btn-primary btn-sm" @click="ContinueDelete();">Continue</button><button iservice-id="Cancel" class="btn btn-link" v-on:click="cancel()">Cancel</button></div>
                       
                            </div>
                            
                        </div>

                        <div class="form-horizontal" v-if="showDetails.deleteContinue">
                            <div class="col-md-12">
                                <p>This mailbox has interactions that need to be associated with
                            another mailbox for reporting purposes. Select a new mailbox and 
                            then select delete.</p>   
                            </div>
                            <div class="col-md-12">
                              <div class="w-50 float-start">
                                <Multiselect
                                    v-model="propertytype"
                                    placeholder="-Select or search for a mailbox to receive interaction-"
                                    label="name"
                                    :groups="false"
                                    :searchable="true"
                                    :class="w-50"
                                    :options="[
                                            { value: 'Sales(Default)', name: 'sales@1to1service.com',level:'0'},
                                        
                                    ]"
                                    >
                                    <template  v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.value }} <br>{{option.name}}</span>
                                    </template>
                                </Multiselect>
                                
                                </div>
                                <div class="w-50 float-start ps-2"><button iservice-id="Continue" class="btn btn-danger btn-sm" @click="DeleteMailbox();">Delete Mailbox</button><button iservice-id="Cancel" class="btn btn-link" v-on:click="cancel()">Cancel</button></div>
                       
                            </div> 
                        </div>

                         <div class="row" v-if="showDetails.confirmDelete">
                            <div class="form-horizontal">
                                <div class="col-md-12">
                                    <p>All service levels that reference this Mailbox property will lose this value when deleted.</p>
                                    <p class="text-danger">WARNING: Deletion is immediate and irrevocable.</p>
                                </div>
                            </div>
                            <div class="form-horizontal">
                                <div class="col-md-12"><button iservice-id="Confirm" class="btn btn-danger btn-sm">Confirm Deletion</button><button iservice-id="Cancel" class="btn btn-link" v-on:click="cancel()">Cancel</button></div>
                            </div>
                         </div>
                    </div>

                    </section>
                </div>
            </div>
         
        </div>
    </div>
  
    <div  v-if="showDetails.showMailboxDetails">
        <div class="box box-info with-border">
            <div class="box-body table-responsive">
              
                     <MailboxDetail></MailboxDetail>
               
            </div>
        </div>
    </div>

</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Toggle from '@vueform/toggle'
import InboxMessageDetail from '../components/InboxMessageDetail.vue';
import iServiceSwitch from '../components/iServiceSwitch.vue';
import NewMailboxPropertyForm from '../components/NewMailboxPropertyForm.vue';
import MailboxDetail from '../components/MailboxDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Mailbox Porperty Settings',
   components: {
    InboxMessageDetail,
    iServiceSwitch,
    Multiselect,
    NewMailboxPropertyForm,
    Toggle,
    Multiselect,
    MailboxDetail
  },
  setup(){      
       
       var showDetails = reactive({confirmDelete:false,deleteMailboxAlert:true,deleteContinue:false, showNew:false,deleteAlert:false, showMailboxDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';
       var deleteMailboxAlert = reactive(true);
       var deleteContinue = reactive(false);
       
       function ContinueDelete(){
          showDetails.deleteMailboxAlert = false;
          showDetails.confirmDelete = false;
          showDetails.deleteContinue = true;
       }
       function DeleteMailbox(){
          showDetails.deleteMailboxAlert = false;
           showDetails.confirmDelete = true;
          showDetails.deleteContinue = false;
       }
       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showMailboxDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showMailboxDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showMailboxDetails=false;
       }

       return{ContinueDelete,DeleteMailbox ,deleteContinue,cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
