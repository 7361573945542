<template>
    <Breadcrumb page-title="_iService Settings " page-section="Settings "> </Breadcrumb>
    <section class="content">
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" v-bind:id="'tab11'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_11_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"> <i class="fa fa-ellipsis-h"></i> <span>Details</span></a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab21'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_21_'+tabOption" type="button" role="tab"><i class="fa fa-list-alt"></i> <span>Interaction Properties</span></a>
          </li>

          <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab31'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_31_'+tabOption" type="button" role="tab"><i class="fa fa-list-alt"></i> <span>Contact Properties</span></a>
          </li>

           <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab41'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_41_'+tabOption" type="button" role="tab"><i class="fa fa-inbox"></i> <span>Mailbox</span></a>
          </li>

          <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab51'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_51_'+tabOption" type="button" role="tab"><i class="fa fa-bell"></i> <span>Notification</span></a>
          </li>

          <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab61'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_61_'+tabOption" type="button" role="tab"><i class="fa fa-reply"></i> <span>Stock Responses</span></a>
          </li>

          <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab71'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_71_'+tabOption" type="button" role="tab"><i class="fa fa-bars"></i> <span>Topics</span></a>
          </li>

           <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab81'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_81_'+tabOption" type="button" role="tab"><i class="fa fa-filter"></i> <span>Filter</span></a>
          </li>
      </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane show active" v-bind:id="'tab_11_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
             <DetailsSettings></DetailsSettings>
          </div>
          <div class="tab-pane" v-bind:id="'tab_21_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
            <InteractionPropertiesSettings></InteractionPropertiesSettings>
          </div>

           <div class="tab-pane" v-bind:id="'tab_31_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
            <ContactPropertiesSettings></ContactPropertiesSettings>
          </div>

          <div class="tab-pane" v-bind:id="'tab_41_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
            <MailboxSettings></MailboxSettings>
          </div>

          
          <div class="tab-pane" v-bind:id="'tab_51_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
            <NotificationSettings></NotificationSettings>
          </div>
          
          <div class="tab-pane" v-bind:id="'tab_61_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
            <StockResponseSettings></StockResponseSettings>
          </div>

          
          <div class="tab-pane" v-bind:id="'tab_71_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
            <TopicsSettings></TopicsSettings>
          </div>

           <div class="tab-pane" v-bind:id="'tab_81_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
            <FilterSettings></FilterSettings>
          </div>
        </div>
      </div>
    </section>
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
 // @ is an alias to /src
 import Breadcrumb from '../components/Breadcrumb.vue';
 import DetailsSettings from '../components/DetailsSettings.vue';
 import InteractionPropertiesSettings from '../components/InteractionPropertiesSettings.vue';
 import ContactPropertiesSettings from '../components/ContactPropertiesSettings.vue';
 import MailboxSettings from '../components/MailboxSettings.vue';
 
 import NotificationSettings from '../components/NotificationSettings.vue';
 import StockResponseSettings from '../components/StockResponseSettings.vue';
 import TopicsSettings from '../components/TopicsSettings.vue';
 import FilterSettings from '../components/FilterSettings.vue';


 import Toggle from '@vueform/toggle'
 export default {
   name: 'New Account',
   components: {
     Breadcrumb,
     Toggle,
     InteractionPropertiesSettings,DetailsSettings,ContactPropertiesSettings,MailboxSettings,NotificationSettings,
     StockResponseSettings,TopicsSettings,FilterSettings
   },
   data: function(){
       return{
         segment1 : true,
         segment2 : false,
         segment3 : false,
         segment4 : false,
         segment7: true,
         segment8:true,
         segment10:true,
         segment11:true,
         choosedWebsite : false
       }
          
   },
   ready () {
     $(document).ready(function ($) {
     })
   },
    methods: {
     toggleSegmentCollapsedState(i) {
       this['segment'+i] = !this['segment'+i] 
     }
   }
 }
 </script>
 